import React from 'react';
import NotFoundPage from '@clearscore/website.404';

import Layout from '../components/layout';
import config from '../config/config';

const Component = () => (
    <Layout meta={{ title: '404 - page not found', description: '' }} location="/404">
        {({ commonModules }) => (
            <NotFoundPage
                homePageUrl={config.HOMEPAGE_URL}
                cookiePolicyConfig={commonModules.cookie_policy}
                appStoreConfig={commonModules.app_store}
            />
        )}
    </Layout>
);

export default Component;
